<template>
  <div class="w3-left-align">
    <Visit-status class="w3-show-inline-block flow-margin-right"
                  :status="selectedVisitStatus">
    </Visit-status>
    <VisitorNameHeader :propsVisitor="selectedVisit.visitor"></VisitorNameHeader>
    <div class="w3-margin-top w3-margin-bottom w3-left-align" v-if="visit">
      <div v-if="appointments.length > 0">
        <div class="w3-container zero-bottom w-98pc w3-show-inline-block flow-card flow-padding"
             v-for="appointment in appointments" v-bind:key="appointment.id">

          <div class="w3-container zero-top zero-left">

            <p class="flow-padding-bottom zero-top w-45pc flow-flex-col w3-left">
							<label class="w3-left flow-main-color flow-padding-bottom">{{ $t("domain") }}</label>
							<span class="w3-col w-100pc zero flow-margin-right sort-select">
                    <flow-select class=" w3-border w3-round-medium" type="text" :items="domainTypes" :valueKey="'name'" :valueKeyIndex="'id'" :callback="setSelectedDomain" :preSelectedItem="appointment.domainType"
                                 v-bind:class="!isCurrentAppointmentLinkedToSelectedVisit(appointment) ? 'w3-disabled' : ''">
                    </flow-select>
                </span>
            </p>

            <p class="flow-padding-bottom zero-top w-45pc flow-flex-col w3-right"
               v-bind:class="contacts.length < 1 ? 'w3-disabled' : ''">
              <label class="w3-left flow-main-color flow-padding-bottom">{{ $t("contact") }}</label>
              <span class="w3-col w-100pc zero flow-margin-right sort-select"
                    :class="visit.visitStatus !== 'ARRIVED' ? 'w3-disabled' : ''">
                    <flow-select class=" w3-border w3-round-medium" type="text"
                                 :items="contacts"
                                 :valueKey="'name'"
                                 :valueKeyIndex="'id'"
                                 :mainItem="visit"
                                 :preSelectedItem="preSelectedContact(visit.visitAssignedContactId)"
                                 :callback="setSelectedContact"
                                 v-bind:class="!isCurrentAppointmentLinkedToSelectedVisit(appointment) ? 'w3-disabled' : ''"
                    >
                    </flow-select>
            </span>
            </p>

          </div>



					<div class="w3-container zero-top zero-left">

						<p class="flow-padding-bottom zero-top w-45pc flow-flex-col w3-left">
							<label class="w3-left flow-main-color flow-padding-bottom">{{ $t("appointmentSubtype") }}</label>
							<input v-model="appointment.type" class="w3-input w3-border w3-round-medium" disabled="disabled" type="text" v-bind:placeholder="$t('type')"/>
						</p>

						<p class="flow-padding-bottom zero-top w-45pc flow-flex-col w3-right">
							<label class="w3-left flow-main-color flow-padding-bottom">{{ $t("visitReceivedContact") }}</label>
							<input :value="visit.visitReceivedContact" class="w3-input w3-border w3-round-medium" disabled="disabled" type="text" v-bind:placeholder="$t('visitReceivedContact')"/>
						</p>

					</div>

          <div class="w3-container zero-top zero-left">

            <p class="flow-padding-bottom zero-top w-45pc flow-flex-col w3-left">
              <label class="w3-left flow-main-color flow-padding-bottom">{{ $t("cause") }}</label>
              <input v-model="appointment.cause" class="w3-input w3-border w3-round-medium"
                     disabled="disabled"
                     type="text"
                     v-bind:placeholder="$t('cause')"/>
            </p>

            <p class="flow-padding-bottom zero-top w-45pc flow-flex-col w3-right">
              <label class="w3-left flow-main-color flow-padding-bottom">{{ $t("source") }}</label>
              <input :value="source(appointment)" class="w3-input w3-border w3-round-medium"
                     disabled="disabled"
                     type="text"
                     v-bind:placeholder="$t('source')"/>
            </p>

          </div>


          <div class="w3-container zero-top zero-left">

            <div class="w3-row">
              <p class="flow-padding-bottom zero-top w-45pc flow-flex-col w3-left">
                <label class="w3-left flow-main-color flow-padding-bottom">{{ $t("time") }}</label>
                <input v-model="appointment.timeStr" class="w3-input w3-border w3-round-medium time-picker-trigger"
                       :class="visit.visitStatus!=='ARRIVED'? 'w3-disabled' : ''"
                       :disabled="visit.visitStatus!=='ARRIVED' || !isCurrentAppointmentLinkedToSelectedVisit(appointment)"
                       @blur="validateAppointmentTime(appointment)"
                       @click="openTimePicker = !openTimePicker"
                       type="text"
                       v-bind:placeholder="$t('time')"/>
              </p>

              <p class="flow-padding-bottom zero-top w-45pc flow-flex-col w3-right">
                <label class="w3-left flow-main-color flow-padding-bottom">{{ $t("location") }}</label>
                <input v-model="appointment.location" class="w3-input w3-border w3-round-medium"
                       :class="locationColor(appointment.location)"
                       disabled="disabled"
                       type="text"
                       v-bind:placeholder="$t('location')"/>
              </p>
            </div>
            <div v-if="openTimePicker && isCurrentAppointmentLinkedToSelectedVisit(appointment)" class="w3-row">
              <p class="w3-col w-45pc flow-form-inline-input time-picker-container">
                <time-picker
                    class="flow-card w3-animate-opacity"

                    :preSelected="appointment.timeObj"
                    :onChange="(time)=>{
                      appointment.timeStr= $helper.NumberWithLeadingZeros(time.hr,2)+':'
                      +
                      $helper.NumberWithLeadingZeros(time.min,2)
                    }"
                    :onClose="()=>{openTimePicker = false;}">
                </time-picker>
              </p>
            </div>
            <div v-if="timeError" class="w3-row">
              <div >
                <p><span class="w3-text-red qpp-field-error-message">
                  {{ $t(timeError) }}</span>
                </p>
                <br>
              </div>
            </div>
          </div>


          <div class="w3-container zero-top zero-left">
            <p class="flow-padding-bottom zero-top w-45pc flow-flex-col w3-left">
              <label class="w3-left flow-main-color flow-padding-bottom">{{ $t("waitingRoom") }}</label>
              <span class="sort-select w3-col w-100pc zero flow-margin-right"
                    :class="visit.visitStatus !== 'ARRIVED' ? 'w3-disabled' : ''">
            <flow-select :callback="setSelectedWR" :items="wRooms"
                         :mainItem="appointment"
                         :preSelectedItem="preSelectedItem(visit.visitWaitingRoomId)"
                         :class="!isCurrentAppointmentLinkedToSelectedVisit(appointment) ? 'w3-disabled' : ''"
                         :valueKey="'name'"
                         :valueKeyIndex="'id'"
                         class="w3-block w3-border w3-round-medium"
                         type="text">
            </flow-select>
          </span>
            </p>

            <p class="flow-padding-bottom zero-top w-45pc flow-flex-col w3-right">
              <label class="w3-right flow-main-color flow-padding-bottom">{{ $t("visitorComment") }}</label>
              <input class="w3-input w3-border w3-round-medium" type="text"
                     v-model="appointment.comment"
                     :disabled="!isCurrentAppointmentLinkedToSelectedVisit(appointment)"
                     v-bind:placeholder="$t('comment')"/>
            </p>

          </div>

          <div class="w3-container zero-top zero-left">
            <p class="flow-padding-bottom w-45pc  w3-left">
              <button
                  class="w3-left w-100pc flow-btn w3-btn w3-round w3-border w3-ripple flow-margin-top w3-round-medium m-t-10 p-t-b-6"
                  :title="$t('save')"
                  :disabled="(masterCopy.waitingRoomId===visit.visitWaitingRoomId && (masterCopy.visitAssignedContactId === visit.visitAssignedContactId))
                              || !isCurrentAppointmentLinkedToSelectedVisit(appointment)"
                  @click="saveAppointment(appointment)">
                <i class="fa fa-save flow-green-active-color">
                  <span><b class="flow-margin-left">{{ $t('save') }}</b></span>
                </i>
              </button>
            </p>

            <p class="flow-padding-bottom w3-right w-45pc">
              <button
                  class="w3-right w-100pc flow-btn w3-btn w3-round w3-border w3-ripple flow-margin-top w3-round-medium m-t-10 p-t-b-6"
                  :disabled="visit.visitStatus!=='ARRIVED' || !isCurrentAppointmentLinkedToSelectedVisit(appointment)"
                  @click="callbackCancel">
                <i class="fa fa-trash w3-text-deep-orange">
                  <span><b class="flow-margin-left">{{ $t('cancel') }}</b></span>
                </i>
              </button>
            </p>
          </div>

        </div>
      </div>
      <div v-else class="w3-center">
        {{ $t('noAppointmentsFound') }}
      </div>
    </div>
  </div>

</template>

<script>
import VisitStatus from "../../common/VisitStatus";
import VisitorNameHeader from "../../common/VisitorNameHeader";
import FlowSelect from "../../common/FlowSelect";
import TimePicker from "@/components/common/TimePicker";

export default {
  name: "EditAppointmentForm",
  components: {VisitStatus, VisitorNameHeader, FlowSelect, TimePicker},
  props: ['selectedVisit', "selectedVisitStatus", 'callback', 'callbackCancel', 'domainTypes'],

  data() {
    return {
      visit: {},
      masterCopy: [],
      wRooms: [],
      contacts: [],
      appointments: [],
      timeError: "",
			selectedDomain: {},
      openTimePicker: false
    }
  },
  mounted() {
    this.visit = this.selectedVisit;
    this.appointments = this.visit.appointmentList;
    this.appointments.forEach(appointment => {
      let stringArray = appointment.timeStr.split(":");
      appointment.timeObj = {
        hr: stringArray[0],
        min: stringArray[1]
      };
      appointment.comment = this.$helper.sanitizeHTML(appointment.comment);
    });
    this.$helper.sortByKey(this.appointments, 'time')
    this.getWRooms();
    this.getAllContacts();
    this.manageMasterCopy();
  },
  // watch: {
  //   selectedVisit(newVal) {
  //     console.log(newVal)
  //     this.visit = newVal;
  //     this.appointments = this.visit.appointmentList;
  //     this.$helper.sortByKey(this.appointments, 'time')
  //     this.manageMasterCopy();
  //   }
  // },
  methods: {
		setSelectedDomain(option) {
			this.selectedDomain = option;
		},
    locationColor(location){
      let site = this.$store.getters["sites/getCurrentSite"];
      let siteLocation = site.address;
      if(siteLocation && location){
        if(siteLocation.toLowerCase()!==location.toLowerCase()){
          return "red-input";
        }
      }
      return '';
    },
    getAllContacts() {
      const app = this;
      app.contacts = [];
      app.$restClient.deskDetails.all().then(response => {
        let data = response.data;
        if (data.length > 0) {
          let con = [];
          data.forEach(function (d) {
            if (d.userId !== null) {
              con.push({
                id: d.userId,
                name: d.firstName + " " + d.lastName
              });
            }
          })

          let uniqueContacts = [...new Map(con.map(item => [item['id'], item])).values()];

          this.$helper.sortByKey(uniqueContacts, "name");
          uniqueContacts.unshift({
            id: null,
            name: null
          });

          app.contacts = uniqueContacts;
        }
      }).catch(err => {
        console.log(err);
        app.$notify.notifyError({title: this.$t("failed")});
      })
    },
    manageMasterCopy() {
      this.masterCopy = {
        waitingRoomId: this.visit.visitWaitingRoomId,
        contactUserId: this.visit.contactUserId
      };
    },
    source(appointment) {
      let src = appointment.source ? appointment.source : '';
      let subsrc = appointment.subSource ? (" (" + appointment.subSource + ")") : '';
      return src + subsrc;
    },
    preSelectedItem(_wrId) {
      var wr = null;
      for (let i = 0; i < this.wRooms.length; i++) {
        if (this.wRooms[i].id === _wrId) {
          wr = this.wRooms[i]
          break;
        }
      }
      return wr;
    },
    preSelectedContact(id) {
      var contact = null;
      for (let i = 0; i < this.contacts.length; i++) {
        if (this.contacts[i].id === parseInt(id)) {
          contact = this.contacts[i]
          break;
        }
      }

      return contact;
    },
    setSelectedWR(item) {
      this.visit.visitWaitingRoomId = item.id;
    },
    setSelectedContact(option, visit) {
      visit.visitAssignedContactId = option.id;
      // visit.contactName = option.name;
    },
    getWRooms() {
      const app = this;
      app.desks = [];
      app.$restClient.waitingRooms.all().then(response => {
        app.wRooms = response.data;
        this.$helper.sortByKey(app.wRooms, "name");
        app.wRooms.unshift({
          id: null,
          name: null
        });
      }).catch(err => {
        console.log(err);
        app.$notify.notifyError({title: this.$t("failed")});
      })
    },
    validateAppointmentTime(appointment) {
      const app = this;
      let timeString = appointment.timeStr;
      let valid = false;
      try {
        if (timeString.length === 5) {
          if (timeString.includes(":")) {
            let splits = timeString.split(':');
            if (splits[0].length === 2 && splits[1].length === 2) {
              let number1 = parseInt(splits[0]);
              let number2 = parseInt(splits[1]);
              if (number1 <= 23 && number1 >= 0 && number2 <= 59 && number2 >= 0) {
                valid = true;
              }
            }
          }
        }
      } catch (er) {
        console.log(er);
        valid = false;
      }
      if (!valid) {
        this.timeError = app.$t("timeError")
      } else
        this.timeError = undefined;
    },
    saveAppointment(appointment) {
      let payload = {};
      let date = new Date(appointment.startTime);
      this.validateAppointmentTime(appointment);
      let timeString = appointment.timeStr.split(':');
      date.setHours(timeString[0]);
      date.setMinutes(timeString[1]);
      date.setMilliseconds(0);


      if (!this.timeError) {
        payload = {
          appointmentTime: date,
          waitingRoomId: this.visit.visitWaitingRoomId,
          visitContactId: this.visit.visitAssignedContactId,
					domainTypeId: this.selectedDomain.id,
          comment: appointment.comment
        }
        this.callback(payload);
      }
    },

    isCurrentAppointmentLinkedToSelectedVisit(appointment) {
      return appointment.visitId === this.selectedVisit.visitId;
    }
  }
}
</script>
<style>
.m-t-10 {
  margin-top: 10px;
}

.p-t-b-6 {
  padding: 6px 0 !important;
}
</style>