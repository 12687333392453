module.exports = {
    "local": {
        "localhost:8081": {
            host: "http://localhost:7000",
            redirectUrl: "http://localhost:8081",
            colorScheme: "#006400", // dark green
            synapseApplicationId: "dev.api.qarts.eu"
        }
    },
    "production": {
        // It has a "." because JS is minifying the keys without the quotes
        "view.mediport.partheas.net": {
            host: "https://api.mediport.partheas.net",
            redirectUrl: "https://view.mediport.partheas.net",
            colorScheme: "#232171",
            synapseApplicationId: "api.mediport.partheas.net"
        },
        "view.cdg.partheas.net": {
            host: "https://api.cdg.partheas.net",
            redirectUrl: "https://view.cdg.partheas.net",
            colorScheme: "#d5b26d",
            synapseApplicationId: "api.cdg.partheas.net"
        },
        "view.medicis.partheas.net": {
            host: "https://api.medicis.partheas.net",
            redirectUrl: "https://view.medicis.partheas.net",
            colorScheme: "#00326b",
            synapseApplicationId: "api.medicis.partheas.net"
        },
        "view.ocs.partheas.net": {
            host: "https://api.ocs.partheas.net",
            redirectUrl: "https://view.ocs.partheas.net",
            colorScheme: "#1e73be",
            synapseApplicationId: "api.ocs.partheas.net"
        },
        "view.partheas.partheas.net": {
            host: "https://api.partheas.partheas.net",
            redirectUrl: "https://view.partheas.partheas.net",
            colorScheme: "#4666ff",
            synapseApplicationId: "api.partheas.partheas.net"
        },
        "view.beukenlaan.partheas.net": {
            host: "https://api.beukenlaan.partheas.net",
            redirectUrl: "https://view.beukenlaan.partheas.net",
            colorScheme: "#8BBB57",
            synapseApplicationId: "api.beukenlaan.partheas.net"
        },
        "view.devaart.partheas.net": {
            host: "https://api.devaart.partheas.net",
            redirectUrl: "https://view.devaart.partheas.net",
            colorScheme: "#E5541B",
            synapseApplicationId: "api.devaart.partheas.net"
        },
        "view.dhaacht.partheas.net": {
            host: "https://api.dhaacht.partheas.net",
            redirectUrl: "https://view.dhaacht.partheas.net",
            colorScheme: "#E5541B",
            synapseApplicationId: "api.dhaacht.partheas.net"
        },
        "view.dkl.partheas.net": {
            host: "https://api.dkl.partheas.net",
            redirectUrl: "https://view.dkl.partheas.net",
            colorScheme: "#E5541B",
            synapseApplicationId: "api.dkl.partheas.net"
        },
        "view.dleie.partheas.net": {
            host: "https://api.dleie.partheas.net",
            redirectUrl: "https://view.dleie.partheas.net",
            colorScheme: "#E5541B",
            synapseApplicationId: "api.dleie.partheas.net"
        },
        "view.cpdiest.partheas.net": {
            host: "https://api.cpdiest.partheas.net",
            redirectUrl: "https://view.cpdiest.partheas.net",
            colorScheme: "#E5541B",
            synapseApplicationId: "api.cpdiest.partheas.net"
        },
        "view.dermatokliniek.partheas.net": {
            host: "https://api.dermatokliniek.partheas.net",
            redirectUrl: "https://view.dermatokliniek.partheas.net",
            colorScheme: "#E5541B",
            synapseApplicationId: "api.dermatokliniek.partheas.net"
        },
        "view.grimbergen.docleas.eu": {
            host: "https://api.grimbergen.docleas.eu",
            redirectUrl: "https://view.grimbergen.docleas.eu",
            colorScheme: "#E5541B",
            synapseApplicationId: "api.grimbergen.docleas.eu"
        },
        "view.torhout.docleas.eu": {
            host: "https://api.torhout.docleas.eu",
            redirectUrl: "https://view.torhout.docleas.eu",
            colorScheme: "#E5541B",
            synapseApplicationId: "api.torhout.docleas.eu"
        },
        "PRODUCTION.TENANT_O": {
            host: "PRODUCTION.TENANT_O_API_HOST",
            redirectUrl: "PRODUCTION.TENANT_O_API_REDIRECT_URL",
            colorScheme: "PRODUCTION.TENANT_O_COLOR_SCHEME",
            synapseApplicationId: "PRODUCTION.TENANT_O_SYNAPSE_APP_ID"
        },
        "PRODUCTION.TENANT_P": {
            host: "PRODUCTION.TENANT_P_API_HOST",
            redirectUrl: "PRODUCTION.TENANT_P_API_REDIRECT_URL",
            colorScheme: "PRODUCTION.TENANT_P_COLOR_SCHEME",
            synapseApplicationId: "PRODUCTION.TENANT_P_SYNAPSE_APP_ID"
        },
        "PRODUCTION.TENANT_Q": {
            host: "PRODUCTION.TENANT_Q_API_HOST",
            redirectUrl: "PRODUCTION.TENANT_Q_API_REDIRECT_URL",
            colorScheme: "PRODUCTION.TENANT_Q_COLOR_SCHEME",
            synapseApplicationId: "PRODUCTION.TENANT_Q_SYNAPSE_APP_ID"
        },
        "PRODUCTION.TENANT_R": {
            host: "PRODUCTION.TENANT_R_API_HOST",
            redirectUrl: "PRODUCTION.TENANT_R_API_REDIRECT_URL",
            colorScheme: "PRODUCTION.TENANT_R_COLOR_SCHEME",
            synapseApplicationId: "PRODUCTION.TENANT_R_SYNAPSE_APP_ID"
        },
        "PRODUCTION.TENANT_S": {
            host: "PRODUCTION.TENANT_S_API_HOST",
            redirectUrl: "PRODUCTION.TENANT_S_API_REDIRECT_URL",
            colorScheme: "PRODUCTION.TENANT_S_COLOR_SCHEME",
            synapseApplicationId: "PRODUCTION.TENANT_S_SYNAPSE_APP_ID"
        },
        "PRODUCTION.TENANT_T": {
            host: "PRODUCTION.TENANT_T_API_HOST",
            redirectUrl: "PRODUCTION.TENANT_T_API_REDIRECT_URL",
            colorScheme: "PRODUCTION.TENANT_T_COLOR_SCHEME",
            synapseApplicationId: "PRODUCTION.TENANT_T_SYNAPSE_APP_ID"
        },
        "PRODUCTION.TENANT_U": {
            host: "PRODUCTION.TENANT_U_API_HOST",
            redirectUrl: "PRODUCTION.TENANT_U_API_REDIRECT_URL",
            colorScheme: "PRODUCTION.TENANT_U_COLOR_SCHEME",
            synapseApplicationId: "PRODUCTION.TENANT_U_SYNAPSE_APP_ID"
        },
        "PRODUCTION.TENANT_V": {
            host: "PRODUCTION.TENANT_V_API_HOST",
            redirectUrl: "PRODUCTION.TENANT_V_API_REDIRECT_URL",
            colorScheme: "PRODUCTION.TENANT_V_COLOR_SCHEME",
            synapseApplicationId: "PRODUCTION.TENANT_V_SYNAPSE_APP_ID"
        },
        "PRODUCTION.TENANT_W": {
            host: "PRODUCTION.TENANT_W_API_HOST",
            redirectUrl: "PRODUCTION.TENANT_W_API_REDIRECT_URL",
            colorScheme: "PRODUCTION.TENANT_W_COLOR_SCHEME",
            synapseApplicationId: "PRODUCTION.TENANT_W_SYNAPSE_APP_ID"
        },
        "PRODUCTION.TENANT_X": {
            host: "PRODUCTION.TENANT_X_API_HOST",
            redirectUrl: "PRODUCTION.TENANT_X_API_REDIRECT_URL",
            colorScheme: "PRODUCTION.TENANT_X_COLOR_SCHEME",
            synapseApplicationId: "PRODUCTION.TENANT_X_SYNAPSE_APP_ID"
        },
        "PRODUCTION.TENANT_Y": {
            host: "PRODUCTION.TENANT_Y_API_HOST",
            redirectUrl: "PRODUCTION.TENANT_Y_API_REDIRECT_URL",
            colorScheme: "PRODUCTION.TENANT_Y_COLOR_SCHEME",
            synapseApplicationId: "PRODUCTION.TENANT_Y_SYNAPSE_APP_ID"
        },
        "PRODUCTION.TENANT_Z": {
            host: "PRODUCTION.TENANT_Z_API_HOST",
            redirectUrl: "PRODUCTION.TENANT_Z_API_REDIRECT_URL",
            colorScheme: "PRODUCTION.TENANT_Z_COLOR_SCHEME",
            synapseApplicationId: "PRODUCTION.TENANT_Z_SYNAPSE_APP_ID"
        },
    }
}